<style>
  * { margin:0; padding:0; box-sizing: border-box; }
  html, body {   }
  #app {
    display: flex;
    background:#f5f5f5;
    height:100vh;
  }
  #app > main {
    padding:10px;
    min-width: 1000px;
    display: flex;
    justify-content: center;
    box-sizing:border-box;
    flex-grow:1;
    overflow: auto;
  }
  .previewBrowser {
    transition: .3s;
    width:1366px;
    flex-shrink: 0;
    transform: scale(.7);
    transform-origin: top center;
  }
  .previewBrowser > .frame {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    background:#ddd;
    text-align:center;
    border-radius: 10px 10px 0 0;
    padding: 5px;
  }
  .previewBrowser > .frame i {
    cursor: pointer;
  }
  .previewBrowser > .frame svg {
    vertical-align: -6px
   }
  .previewBrowser > .body {
    position: relative;
    min-height:200px;
    background: #fff;
    overflow:hidden;
  }
  .previewBrowser > .body a {
    pointer-events: none;
  }
  .previewBrowser .body .block:hover {
    border:1px dashed #3333ff;
  }
  .previewBrowser .body .block.selected {
    border:1px dashed #3333ff;
  }
  .previewBrowser.mobile {
    width:360px;
    transform: scale(1);
  }
  #app > aside {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    padding: 10px;
    width:calc(100% - 1000px);
    max-width: 400px;
    flex-shrink: 0;
    border-left:1px solid #ccc;
    box-sizing:border-box;
    background-color:#f5f5f5;
    overflow: auto;
    font-size:.9em;
  }
  #app > aside .actions {
    position: sticky;
    bottom:-10px;
    margin:0 -10px;
    padding:10px;
    background-color: #f5f5f5;

  }
  #app > aside .modules-add {}
  #app > aside .modules-add li { display: block; text-align: center; padding:5px; margin-bottom:3px; background:#fff; }

  #app > aside .button { display:inline-block;  background-color:#888; color:#fff; padding:10px; border:0; font:inherit; cursor: pointer; }
  #app > aside .button + .button { margin-left: 10px; }
  #app > aside .button-primary { background-color:#51a8b1; }

  #app > aside .block-list li {display: flex; padding:5px; margin-bottom:3px; background:#fff; align-items: center; }
  #app > aside .block-list span { flex-grow: 1 }

  #app > aside h2 { margin:0 0 15px; text-align:center; }
  #app > aside h3 { margin:0 0 15px; }

  #app > aside input,
  #app > aside textarea,
  #app > aside select { font:inherit; width:100%; background:#fff; border:0; outline:none;}
  #app > aside textarea{ min-height:300px;}

  #app > aside .block-styles { margin-bottom:20px; }
  #app > aside .block-style { margin-bottom:10px; }
  #app > aside .selector{  margin:0 0 5px; }
  #app > aside .selector span { display:inline-block;margin-left:5px;color:#777; font-size:.8em; }
  #app > aside .declaration{ display: flex; flex-wrap:wrap; align-items:center; margin-bottom:5px; }
  #app > aside .declaration-property{ white-space: nowrap; padding-right:3px; color:#3964ae;}
  #app > aside .declaration-property:after{ content:':'}
  #app > aside .declaration-value{ flex-grow: 1; }
  #app > aside .declaration-value input,
  #app > aside .declaration-value select { background:transparent;}
  #app > aside .declaration-value select {width:auto; border:1px solid #eee;}
  #app > aside .declaration-upload{ margin-top:5px; width:100%; }
  #app > aside .declaration-upload img { max-height:50px; width:auto; max-width: 100%; height:auto;}

  #app > aside .block-contents { margin-bottom:20px; }
  #app > aside .block-content { display: flex; flex-wrap:wrap; align-items:center; margin-bottom:5px; }
  #app > aside .content-name{ white-space: nowrap; padding-right:3px; color:#3964ae; }
  #app > aside .content-name:after{ content:':'}
  #app > aside .content-value{ flex-grow: 1; display: flex; }
  #app > aside .content-value input,
  #app > aside .content-value textarea,
  #app > aside .content-value select { padding:5px;}
  #app > aside .content-value textarea { min-height: 100px }
  #app > aside .content-upload{ margin-top:5px; width:100%; }
  #app > aside .content-upload img { max-height:50px; width:auto; max-width: 100%; height:auto;}

  .ajax-loading{
    position:fixed;
    top:0;
    left: 0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  .ajax-loading progress {

  }

  .block[data-module="yourviews-depoimentos.html"] .b-container:after {
    content:'-- Depoimentos yourviews --';
    display: block;
    text-align: center;
    padding:10px;
    font-size: 30px;
  }

</style>

<template>
  <div id="app">
    <div v-if="isUploading" class="ajax-loading"><progress :progress="progress" max="100">{{progress}}</progress></div>
    <main>
      <div class="previewBrowser" :class="{mobile:device=='mobile'}">
        <div class="frame">
          <span v-if="device=='desktop'">Desktop - zoom 70% <i @click="changeDevice('mobile')"><svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="#000000" d="M4,6V16H9V12A2,2 0 0,1 11,10H16A2,2 0 0,1 18,12V16H20V6H4M0,20V18H4A2,2 0 0,1 2,16V6A2,2 0 0,1 4,4H20A2,2 0 0,1 22,6V16A2,2 0 0,1 20,18H24V20H18V20C18,21.11 17.1,22 16,22H11A2,2 0 0,1 9,20H9L0,20M11.5,20A0.5,0.5 0 0,0 11,20.5A0.5,0.5 0 0,0 11.5,21A0.5,0.5 0 0,0 12,20.5A0.5,0.5 0 0,0 11.5,20M15.5,20A0.5,0.5 0 0,0 15,20.5A0.5,0.5 0 0,0 15.5,21A0.5,0.5 0 0,0 16,20.5A0.5,0.5 0 0,0 15.5,20M13,20V21H14V20H13M11,12V19H16V12H11Z" /></svg></i></span>
          <span v-if="device=='mobile'">Mobile - zoom 100% <i @click="changeDevice('desktop')"><svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="#000000" d="M4,6V16H9V12A2,2 0 0,1 11,10H16A2,2 0 0,1 18,12V16H20V6H4M0,20V18H4A2,2 0 0,1 2,16V6A2,2 0 0,1 4,4H20A2,2 0 0,1 22,6V16A2,2 0 0,1 20,18H24V20H18V20C18,21.11 17.1,22 16,22H11A2,2 0 0,1 9,20H9L0,20M11.5,20A0.5,0.5 0 0,0 11,20.5A0.5,0.5 0 0,0 11.5,21A0.5,0.5 0 0,0 12,20.5A0.5,0.5 0 0,0 11.5,20M15.5,20A0.5,0.5 0 0,0 15,20.5A0.5,0.5 0 0,0 15.5,21A0.5,0.5 0 0,0 16,20.5A0.5,0.5 0 0,0 15.5,20M13,20V21H14V20H13M11,12V19H16V12H11Z" /></svg></i></span>
        </div>
        <div class="body">
          <div v-for="block in blocks" @click="editBlock(block)" class="block" :class="{selected:selectedBlock==block}" :id="'block-'+block.uid" :data-module="block.name" v-html="block.preview"></div>
        </div>
      </div>
    </main>

    <aside>
      <div v-if="tab=='dashboard'">
        <ul class="block-list">
          <li v-for="(block,index) in blocks" :title="block.uid">
            <span>{{ moduleName(block.name) }}</span>
            <button class="button" @click="editBlock(block)">editar</button>
            <button class="button" v-show="index>0" @click="moveBlockUp(block)">↥</button>
            <button class="button" v-show="index<blocks.length-1" @click="moveBlockDown(block)">↧</button>
          </li>


        </ul>
        <div style="float:right;">
          <button class="button" v-if="blocks.length" @click="exportHtml">⚙ Exportar html</button>
        </div>
        <button class="button button-primary"  @click="addModule">⊞ Adicionar bloco</button>

      </div>
      <div v-if="tab=='exportHtml'">
        <h2>Exportar html</h2>
        <textarea class="output">{{ output }}</textarea>
        <button class="button" @click="tabDashboard">Voltar</button>
      </div>
      <div v-if="tab=='importHtml'">
        <h2>Importar Html</h2>
        <textarea class="input" v-model="inputHtml"></textarea>
        <button class="button button-primary"  @click="onSubmitImport">Importar</button>
        <button class="button" @click="tabDashboard">Voltar</button>
      </div>


      <div v-if="tab=='addModule'">
        <h2>Adicionar bloco</h2>
        <ul class="modules-add">
          <li v-for="module in modules" @click="insertBlock(module)">{{ moduleName(module) }}</li>
        </ul>
        <div style="float:right;">
          <button class="button" @click="importHtml">⚙ importar html</button>
        </div>
        <button class="button" @click="tabDashboard">Cancel</button>
      </div>

      <div v-if="tab=='editBlock'">
        <h2>{{ moduleName(selectedBlock.name) }}</h2>
        <div v-if="selectedBlock.contents.length" class="block-contents">
          <h3>Conteúdo</h3>
          <div v-for="content in selectedBlock.contents" class="block-content">
            <div class="content-name">{{ content.name }}</div>
            <div class="content-value" v-if="content.type=='html' || content.type=='textarea'"><textarea v-model="content.value" @blur="updateSource(selectedBlock)"></textarea></div>
            <div class="content-value" v-else>
              <input type="text" v-model="content.value" @blur="updateSource(selectedBlock)">
              <span class="button" v-if="content.allowLink && !content.hasLink" @click="link(content)" title="insert link">🔗</span>
              <span class="button" v-if="content.type=='url'" @click="unlink(content)" title="remove link">×</span>
            </div>
            <div class="content-upload" v-if="content.type=='image'">
              <img v-if="content.value" :src="content.value">
              <input type="file" @change="onFileChange($event,content,'value')">
            </div>
          </div>
        </div>
        <div v-if="selectedBlock.styles.desktop.length" class="block-styles">
          <h3>Desktop</h3>
          <div v-for="style in selectedBlock.styles.desktop" class="block-style">
            <div class="selector"><strong v-if="style.label">{{ style.label }}</strong> <span>{{ style.selector }}</span></div>
            <div class="declarations">
              <div v-for="declaration in style.declarations" class="declaration" v-if="!declaration.hidden">


                <div class="declaration-property">{{ propertyName(declaration.property) }}</div>

                <div class="declaration-value" v-if="declaration.property=='font-family'">
                  <input list="font" type="text" v-model="declaration.value" @blur="updateSource(selectedBlock)">
                </div>
                <div class="declaration-value" v-else-if="declaration.property=='font-weight'">
                  <select v-model="declaration.value" @change="updateSource(selectedBlock)">
                    <option value="400">400 Normal</option>
                    <option value="500">500 Medium</option>
                    <option value="700">700 Bold</option>
                    <option value="900">900 Black</option>
                  </select>
                </div>
                <div class="declaration-value" v-else-if="declaration.property=='background-size'">
                  <input list="background-size" type="text" v-model="declaration.value" @blur="updateSource(selectedBlock)">
                </div>
                <div class="declaration-value" v-else-if="declaration.property=='text-align'">
                  <input list="text-align" type="text" v-model="declaration.value" @blur="updateSource(selectedBlock)">
                </div>
                <div class="declaration-value" v-else-if="declaration.property=='align-items'">
                  <input list="align-items" type="text" v-model="declaration.value" @blur="updateSource(selectedBlock)">
                </div>
                <div class="declaration-value" v-else-if="declaration.property=='justify-content'">
                  <input list="justify-content" type="text" v-model="declaration.value" @blur="updateSource(selectedBlock)">
                </div>
                <div class="declaration-value" v-else-if="declaration.property=='background-repeat'">
                  <select v-model="declaration.value" @change="updateSource(selectedBlock)">
                    <option value="no-repeat">no-repeat</option>
                    <option value="repeat">repeat</option>
                    <option value="repeat-x">repeat-x</option>
                    <option value="repeat-y">repeat-y</option>
                  </select>
                </div>
                <div class="declaration-value" v-else-if="declaration.property=='background-position'">
                  <input list="background-position" type="text" v-model="declaration.value" @blur="updateSource(selectedBlock)">
                </div>
                <div v-else class="declaration-value">
                  <input type="text" v-model="declaration.value" @blur="updateSource(selectedBlock)">
                </div>



                <div class="declaration-upload" v-if="declaration.property=='background-image'">
                  <img :src="extractUrl(declaration.value)">
                  <input type="file" @change="onFileChange($event,declaration,'value')">
                </div>

              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedBlock.styles.mobile.length" class="block-styles">
          <h3>Mobile</h3>
          <div v-for="style in selectedBlock.styles.mobile" class="block-style">
            <div class="selector"><strong v-if="style.label">{{ style.label }}</strong> <span>{{ style.selector }}</span></div>
            <div class="declarations">
              <div v-for="declaration in style.declarations" class="declaration" v-if="!declaration.hidden">


                <div class="declaration-property">{{ propertyName(declaration.property) }}</div>

                <div class="declaration-value" v-if="declaration.property=='font-family'">
                  <input list="font" type="text" v-model="declaration.value" @blur="updateSource(selectedBlock)">
                </div>
                <div class="declaration-value" v-else-if="declaration.property=='font-weight'">
                  <select v-model="declaration.value" @change="updateSource(selectedBlock)">
                    <option value="400">400 Normal</option>
                    <option value="500">500 Medium</option>
                    <option value="700">700 Bold</option>
                    <option value="900">900 Black</option>
                  </select>
                </div>
                <div class="declaration-value" v-else-if="declaration.property=='background-size'">
                  <input list="background-size" type="text" v-model="declaration.value" @blur="updateSource(selectedBlock)">
                </div>
                <div class="declaration-value" v-else-if="declaration.property=='text-align'">
                  <input list="text-align" type="text" v-model="declaration.value" @blur="updateSource(selectedBlock)">
                </div>
                <div class="declaration-value" v-else-if="declaration.property=='align-items'">
                  <input list="align-items" type="text" v-model="declaration.value" @blur="updateSource(selectedBlock)">
                </div>
                <div class="declaration-value" v-else-if="declaration.property=='justify-content'">
                  <input list="justify-content" type="text" v-model="declaration.value" @blur="updateSource(selectedBlock)">
                </div>
                <div class="declaration-value" v-else-if="declaration.property=='background-repeat'">
                  <select v-model="declaration.value" @change="updateSource(selectedBlock)">
                    <option value="no-repeat">no-repeat</option>
                    <option value="repeat">repeat</option>
                    <option value="repeat-x">repeat-x</option>
                    <option value="repeat-y">repeat-y</option>
                  </select>
                </div>
                <div class="declaration-value" v-else-if="declaration.property=='background-position'">
                  <input list="background-position" type="text" v-model="declaration.value" @blur="updateSource(selectedBlock)">
                </div>
                <div v-else class="declaration-value">
                  <input type="text" v-model="declaration.value" @blur="updateSource(selectedBlock)">
                </div>



                <div class="declaration-upload" v-if="declaration.property=='background-image'">
                  <img :src="extractUrl(declaration.value)">
                  <input type="file" @change="onFileChange($event,declaration,'value')">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="actions">
          <div style="float:right;">
            <span class="button" @click="viewSource">&lt;/&gt;</span>
            <span class="button" @click="removeBlock(selectedBlock)">×</span>
          </div>
          <button class="button" @click="tabDashboard">voltar</button>
        </div>
      </div>

      <div v-if="tab=='editBlockSource'">
        <h2>{{ moduleName(selectedBlock.name) }}</h2>
        <textarea v-model="selectedBlock.source" @blur="parseSource(selectedBlock)"></textarea>
        <div style="float:right;">
          <span class="button" @click="newModule" title="Salvar como novo módulo">⚙</span>
        </div>
        <button class="button" @click="tab='editBlock'">voltar</button>
      </div>

      <div v-if="tab=='newModule'">
        <h2>Salvar template de bloco</h2>
        <form @submit.prevent="onSubmitNewModule">
          <input type="text" v-model="module.name">
          <button class="button" type="submit">save</button>
          <span class="button" @click.prevent="editBlock(selectedBlock)">cancelar</span>
        </form>
      </div>
    </aside>

    <datalist id="font">
      <option value="Din"></option>
      <option value="DinCond"></option>
    </datalist>
    <datalist id="background-size">
      <option value="cover"></option>
      <option value="contain"></option>
      <option value="100%"></option>
      <option value="auto 100%"></option>
    </datalist>
    <datalist id="text-align">
      <option value="left"></option>
      <option value="center"></option>
      <option value="right"></option>
      <option value="justify"></option>
    </datalist>
    <datalist id="align-items">
      <option value="stretch"></option>
      <option value="flex-start"></option>
      <option value="center"></option>
      <option value="flex-end"></option>
    </datalist>
    <datalist id="justify-content">
      <option value="space-between"></option>
      <option value="space-around"></option>
      <option value="flex-start"></option>
      <option value="center"></option>
      <option value="flex-end"></option>
    </datalist>
    <datalist id="background-position">
      <option value="center"></option>
      <option value="center left"></option>
      <option value="center right"></option>
      <option value="top left"></option>
      <option value="top center"></option>
      <option value="top right"></option>
      <option value="bottom left"></option>
      <option value="bottom center"></option>
      <option value="bottom right"></option>
    </datalist>

  </div>
</template>

<script>

export default {
  name: 'app',
  components: {},
  data(){
    return {
      blocks:[],
      selectedBlock:{},
      module:{},
      modules:[],
      tab:'dashboard',
      device:'desktop',
      isUploading:false,
      progress:0,
      output:'',
      inputHtml:'',
    }
  },
  computed:{
    /*
    generated(){
      var output = [];
      this.blocks.forEach((block)=>{
        output.push('<div><style>'+block.previewCss+'</style>'+block.source.html+'</div>');
      });
      return output.join("\n");
    }
    */
  },
  methods:{
    propertyName(property){
      if(property=='font-family') return 'fonte';
      if(property=='font-size') return 'tamanho da fonte';
      if(property=='font-weight') return 'peso da fonte';
      if(property=='color') return 'cor da fonte';
      if(property=='text-align') return 'alinhamento do texto';
      if(property=='background-color') return 'cor de fundo';
      if(property=='background-size') return 'tamanho do fundo';
      if(property=='background-repeat') return 'repetir fundo';
      if(property=='background-position') return 'posição do fundo';
      if(property=='padding') return 'padding';
      if(property=='padding-top') return 'padding acima';
      if(property=='padding-bottom') return 'padding abaixo';
      if(property=='padding-left') return 'padding esquerda';
      if(property=='padding-right') return 'padding direita';
      if(property=='margin') return 'margem';
      if(property=='margin-top') return 'margem acima';
      if(property=='margin-bottom') return 'margem abaixo';
      if(property=='margin-left') return 'margem esquerda';
      if(property=='margin-right') return 'margem direita';
      if(property=='width') return 'largura';
      if(property=='min-width') return 'largura min';
      if(property=='max-width') return 'largura máx';
      if(property=='height') return 'altura';
      if(property=='min-height') return 'altura min';
      if(property=='max-height') return 'altura máx';
      if(property=='flex-basis') return 'largura base';
      if(property=='align-items') return 'alinhamento vertical';
      if(property=='justify-content') return 'alinhamento do conteúdo';

      return property;
    },
    moduleName(name){
      return name.replace(/\.html$/,'').replace(/-/g,' ');
    },
    exportHtml(){
      //this.output = document.querySelector('.previewBrowser .body').innerHTML;
      var output = '';
      this.blocks.forEach((block)=>{
        // var html = (block.source.replace(/[\n\t]/g,''));
        var html = block.source;

        var prefix = '#block-'+block.uid+' ';
        var css = block.styles.desktop.reduce((carry,style)=>
         carry
         + prefix + style.selector + ' { '
         +(style.label?'/* '+style.label+' */':'')+"\n"
         + style.declarations.reduce((carry,item) => carry + "  " + item.property+": "+item.value+";"+(item.obs?'/* '+item.obs+' */':'')+"\n",'')
         +" }\n"
         ,'');

        css += "@media (max-width:991px) {\n";

        css += block.styles.mobile.reduce((carry,style)=>
         carry
         + "  " + prefix + style.selector + ' { '
         +(style.label?'/* '+style.label+' */':'')+"\n"
         + style.declarations.reduce((carry,item) => carry + "    " + item.property+": "+item.value+";\n",'')
         +" }\n"
         ,'');

        css += "}";

        html = html.replace(/<style>([\s\S]*?)<\/style>/,'<style>'+css+'</style>');



        output += '<div id="block-'+block.uid+'" data-module="'+block.name+'" class="block">'+html+'</div>'+"\n";
      })



      this.output = output;
      this.tab = 'exportHtml';
    },
    importHtml(){
      this.tab = 'importHtml';
      this.inputHtml = '';
    },
    onSubmitImport(){
      var html = this.inputHtml;
      if(html.match(/<div id="block-\d+" data-module="[^"]+" class="block">/)){
        var match = html.match(/<div id="block-\d+" data-module="[^"]+" class="block">/g);
        //console.log(match);
        for (var i in match){
          var start = html.indexOf(match[i]);
          var nextIndex = parseInt(i)+1;
          var end = match[nextIndex]?html.indexOf(match[nextIndex]):html.length;
          console.log('prox',match[nextIndex],end);
          var blockHtml = html.substring(start,end);
          var matchBlock =  blockHtml.match(/<div id="block-(\d+)" data-module="([^"]+)" class="block">([\s\S]+)<\/div>/);
          console.log(matchBlock);
          if(!matchBlock) continue;

          var regexp = new RegExp('#block-'+matchBlock[1],'g');
          var block = {
            name:matchBlock[2],
            uid:matchBlock[1],
            source:matchBlock[3].replace(regexp,''),
            contents:[],
            styles:[],
          }
          this.parseSource(block);
          this.blocks.push(block);
        }
        this.tabDashboard();
      } else {
        var block = {
          name:'Importado',
          uid:new Date().getTime(),
          source:html,
          contents:[],
          styles:[],
        }
        this.parseSource(block);
        this.blocks.push(block);
        this.editBlock(block);
      }
    },
    viewSource(){
      this.tab='editBlockSource';
    },
    changeDevice(device){
      this.device = device;
      this.blocks.forEach((block)=>{ this.updateBlockPreview(block); })
    },
    addModule(){
      this.tab = 'addModule';
    },
    newModule(){
      this.module = this.selectedBlock;
      this.module.name = this.selectedBlock.name.replace(/^(.+)\.(.+)$/,'$1-copy.$2');
      this.tab = 'newModule';
    },
    tabDashboard(){
      this.selectedBlock = null;
      this.tab = 'dashboard';
    },
    removeBlock(block){
      var index = this.blocks.indexOf(block);
      this.blocks.splice(index,1);
      this.tabDashboard();
    },

    moveBlockDown(block){
      var index = this.blocks.indexOf(block);
      var handler = this.blocks.splice(index,1);
      this.blocks.splice(index+1,0,block);

    },
    moveBlockUp(block){
      var index = this.blocks.indexOf(block);
      var handler = this.blocks.splice(index,1);
      this.blocks.splice(index-1,0,block);
    },

    onSubmitNewModule(){
      var data = {
        name:this.module.name,
        source:this.module.source,
      }
      this.$http.post('/newModule.php',data)
      .then((response)=> {
        this.module.uid = new Date().getTime();
        this.selectedBlock = this.module;
        this.updateBlockPreview(this.selectedBlock)
        this.modules.push(this.module.name);
        this.tab = 'editBlock';
      }).catch((error) =>  { console.error(error); if(error.response) alert(error.response.data); })

    },
    onFileChange(event,obj,field){

      var files = event.target.files || event.dataTransfer.files;

      var file = files[0];
      if(!file) return;

      this.isUploading = true;
      var reader = new FileReader();
      reader.onload = (e) => {
        var base64 =  e.target.result;
        var data = {
          name:file.name,
          content:base64
        }

        this.$http.post('/upload.php',data,{
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
            if (totalLength !== null) {
              this.progress = Math.round( (progressEvent.loaded * 100) / totalLength );
            }
          }
        })
        .then((response)=> {
          this.isUploading = false;
          obj[field] = obj[field].replace(this.extractUrl(obj[field]),response.data);
          this.updateSource(this.selectedBlock);
        }).catch((error) =>  { console.error(error); if(error.response) alert(error.response.data); })
      };
      reader.readAsDataURL(file);
    },
    extractUrl(string){
      var match = string.match(/url\(([^)]+)\)/i);
      if(match) return match.pop();
      var match = string.match(/^https?:\/\/[^\s]+/i);
      if(match) return match.pop();
      return '';
    },
    editBlock(block){
      this.selectedBlock = block;
      this.tab = 'editBlock';
    },
    insertBlock(module){
      this.$http.get('/modules.php?id='+module)
      .then((response)=> {
        var block = {
          name:module,
          uid:new Date().getTime(),
          source:response.data,
          contents:[],
          styles:[],
        }
        this.parseSource(block);

        this.blocks.push(block);
        this.editBlock(block);
      }).catch((error) =>  { console.error(error); if(error.response) alert(error.response.data); })
    },

    createVirtualDom(block){
      block.virtualDom = block.source.replace(/<style>[\s\S]*?<\/style>/,'').replace(/<script>[\s\S]*?<\/script>/,'').trim();

      var match = block.virtualDom.match(/((href|src)="[^"]*"|url\([^)]*\)|<!-- b-(text|textarea|html)(="[^"]*")?{ -->[\s\S]*?<!-- } -->)/g);

      block.virtualDom = block.virtualDom.replace(/href="([^"]*)"/g,'href=""');
      block.virtualDom = block.virtualDom.replace(/src="([^"]*)"/g,'src=""');
      block.virtualDom = block.virtualDom.replace(/url\([^)]*\)/g,'url()');
      block.virtualDom = block.virtualDom.replace(/<!-- b-(text|textarea|html)(="[^"]*")?{ -->[\s\S]*?<!-- } -->/g,'<!-- b-$1$2{ --><!-- } -->');

      if(match) for(let i in match){
        var domRef = '#content'+i;
        if(match[i].match(/href="([^"]*)"/)){
          block.virtualDom = block.virtualDom.replace('href=""','href="'+domRef+'"');
        }
        if(match[i].match(/src="([^"]*)"/)){
          block.virtualDom = block.virtualDom.replace('src=""','src="'+domRef+'"');
        }
        if(match[i].match(/url\([^)]*\)/)){
          block.virtualDom = block.virtualDom.replace('url()','url('+domRef+')');
        }
        if(match[i].match(/<!-- b-(text|textarea|html)(="[^"]*")?{ -->[\s\S]*?<!-- } -->/)){
          block.virtualDom = block.virtualDom.replace(/<!-- b-(text|textarea|html)(="[^"]*")?{ --><!-- } -->/,'<!-- b-$1$2{ -->'+domRef+'<!-- } -->');
        }


      }

    },
    parseSource(block){
      this.createVirtualDom(block);
      block.contents = [];
      var html = block.source.replace(/<style>[\s\S]*?<\/style>/,'').replace(/<script>[\s\S]*?<\/script>/,'').trim();

      var counter = {};
      var matchContent;
      var match = html.match(/((href|src)="[^"]*"|url\([^)]*\)|<!-- b-(text|textarea|html)(="[^"]*")?{ -->[\s\S]*?<!-- } -->)/g);
      if(match) for(let i in match){
        var content = {
          domRef:'#content'+i,
        }
        if(match[i].match(/href="([^"]*)"/)){
          content.name = 'Link '+ (counter.link?++counter.link:counter.link=1);
          content.value = match[i].match(/href="([^"]*)"/).pop();
          content.type = 'url';
        }
        if(match[i].match(/src="([^"]*)"/)){
          content.name = 'Imagem '+ (counter.img?++counter.img:counter.img=1);
          content.value = match[i].match(/src="([^"]*)"/).pop();
          content.type = 'image';
          content.allowLink=true;
          var regExp = new RegExp('<a[^>]*href="[^"]*"[^>]*>\\s*<img[^>]*src="'+content.domRef+'"');
          content.hasLink = !!block.virtualDom.match(regExp);
        }
        if(match[i].match(/url\([^)]*\)/)){
          content.name = 'Imagem '+ (counter.img?++counter.img:counter.img=1);
          content.value = match[i].match(/url\(([^)]*)\)/).pop();
          content.type = 'image';
        }
        if(matchContent = match[i].match(/<!-- b-(text|textarea|html)(?:="([^"]*)")?{ -->([\s\S]*?)<!-- } -->/)){
          if(matchContent[2]){
            content.name = matchContent[2]+' '+ (counter[matchContent[2]]?++counter[matchContent[2]]:counter[matchContent[2]]=1);
          } else {
            content.name = 'Texto '+ (counter.text?++counter.text:counter.text=1);
          }
          content.value = matchContent[3].trim();
          content.type = matchContent[1];
          if(content.type=='textarea') content.value = content.value.replace(/<br ?(\/)?>/g,"\n");
          if(content.type=='text'){
            content.allowLink=true;
            var regExp = new RegExp('<a[^>]*href="[^"]*"[^>]*>\\s*<!-- b-text(?:="([^"]*)")?{ -->'+content.domRef+'<!-- } -->');
            content.hasLink = !!block.virtualDom.match(regExp);
          }
        }

        block.contents.push(content);
      }

      //separar desk e mobile
      var css = ''
      var match = null;
      if (match = block.source.match(/<style>([\s\S]*?)<\/style>/)){
        css = match.pop().trim();
      }
      var desktop = css.replace(/\s*@media\s?\([^)]+\)\s?{\s*([\s\S]+?})\s*}/gm,'');
      var medias = css.match(/\s*@media\s?\([^)]+\)\s?{\s*([\s\S]+?})\s*}/gm);
      var mobile = [];
      for(let i in medias) mobile.push(medias[i].replace(/\s*@media\s?\([^)]+\)\s?{\s*([\s\S]+?})\s*}/m,'$1'));
      mobile = mobile.join("\n");

      var styles = {
        desktop:[],
        mobile:[],
      }

      var selectors = desktop.match(/\s*([^{]+){\s*(\/\*[^*]+\*\/)?([^}]+)}/gm);
      for(let i in selectors){
        var match = selectors[i].match(/\s*([^{]+){\s*(?:\/\*\s*([^*]+)\s*\*\/)?([^}]+)}/m);
        var obj = {
          selector:match[1],
          label:match[2],
          declarations:[],
        };

        var declarations = match[3].match(/\s*([^:]+?)\s*:\s*([^;]+?);\s*(\/\*[^*]*\*\/)?/g)
        for(let x in declarations){
          var matchDeclaration = declarations[x].match(/\s*([^:]+?)\s*:\s*([^;]+?);\s*(?:\/\*\s*([^*]*?)\s*\*\/)?/);
          if(matchDeclaration) obj.declarations.push({
            property:matchDeclaration[1],
            value:matchDeclaration[2],
            obs:matchDeclaration[3],
            hidden:matchDeclaration[3]=='hidden',
          });
        }
        styles.desktop.push(obj);
      }

      var selectors = mobile.match(/\s*([^{]+){\s*(\/\*[^*]+\*\/)?([^}]+)}/gm);
      for(let i in selectors){
        var match = selectors[i].match(/\s*([^{]+){\s*(?:\/\*\s*([^*]+)\s*\*\/)?([^}]+)}/m);
        var obj = {
          selector:match[1],
          label:match[2],
          declarations:[],
        };
        var declarations = match[3].match(/\s*([^:]+?)\s*:\s*([^;]+?);\s*(\/\*[^*]*\*\/)?/g)
        for(let x in declarations){
          var matchDeclaration = declarations[x].match(/\s*([^:]+?)\s*:\s*([^;]+?);\s*(?:\/\*\s*([^*]*?)\s*\*\/)?/);
          if(matchDeclaration) obj.declarations.push({
            property:matchDeclaration[1],
            value:matchDeclaration[2],
            obs:matchDeclaration[3],
            hidden:matchDeclaration[3]=='hidden',
          });
        }
        styles.mobile.push(obj);
      }

      block.styles = styles;
      this.updateBlockPreview(block);
    },
    unlink(content){

      var html = this.selectedBlock.virtualDom;

      var regExp = new RegExp('<a[^>]*href="'+content.domRef+'">([\\s\\S]*?)</a>');
      html = html.replace(regExp,'$1');

      this.selectedBlock.contents.forEach((content)=>{
        var value = content.value;
        if(content.type=='textarea') value = value.replace(/\n/g,'<br>');
        html = html.replace(content.domRef,value);
      });

      var css = this.selectedBlock.source.match(/<style>([\s\S]*?)<\/style>/).pop().trim();
      this.selectedBlock.source = '<style>'+css+'</style>'+html;
      this.parseSource(this.selectedBlock);

    },
    link(content){

      var html = this.selectedBlock.virtualDom;

      var regExp = new RegExp('(<img[^>]*src="'+content.domRef+'"[^>]*>)');
      html = html.replace(regExp,'<a href="">$1</a>');
      var regExp = new RegExp('(<!-- b-text(?:="[^"]*")?{ -->'+content.domRef+'<!-- } -->)');
      html = html.replace(regExp,'<a href="">$1</a>');

      this.selectedBlock.contents.forEach((content)=>{
        var value = content.value;
        if(content.type=='textarea') value = value.replace(/\n/g,'<br>');
        html = html.replace(content.domRef,value);
      });

      var css = this.selectedBlock.source.match(/<style>([\s\S]*?)<\/style>/).pop().trim();
      this.selectedBlock.source = '<style>'+css+'</style>'+html;
      this.parseSource(this.selectedBlock);

    },
    updateSource(block){
      var css = block.styles.desktop.reduce((carry,style)=>
       carry
       + style.selector + ' { '
       +(style.label?'/* '+style.label+' */':'')+"\n"
       + style.declarations.reduce((carry,item) => carry + "  " + item.property+": "+item.value+";"+(item.obs?'/* '+item.obs+' */':'')+"\n",'')
       +" }\n"
       ,'');

       css += "@media (max-width:991px) {\n";

       css += block.styles.mobile.reduce((carry,style)=>
       carry
       + "  "+style.selector + ' { '
       +(style.label?'/* '+style.label+' */':'')+"\n"
       + style.declarations.reduce((carry,item) => carry + "    " + item.property+": "+item.value+";\n",'')
       +" }\n"
       ,'');

       css += "}";

      this.createVirtualDom(block);
      var html = block.virtualDom;
      block.contents.forEach((content)=>{
        var value = content.value;
        if(content.type=='textarea') value = value.replace(/\n/g,'<br>');
        html = html.replace(content.domRef,value);
      });

      block.source = '<style>'+css+'</style>'+html;
      this.updateBlockPreview(block);
    },
    updateBlockPreview(block){
      var previewWidth = 1366;
      var prefix = '#block-'+block.uid+' ';
      var css = block.styles.desktop.reduce((carry,style)=>
        carry
        + prefix + style.selector + '{'
        + style.declarations.reduce((carry,item) => carry + item.property+":"+item.value+";",'')
        +"}\n"
       ,'');

      if(this.device=='mobile') {
        previewWidth = 360;

        css += block.styles.mobile.reduce((carry,style)=>
        carry
        + prefix + style.selector + '{'
        + style.declarations.reduce((carry,item) => carry + item.property+":"+item.value+";",'')
        +"}\n"
        ,'');
      }

      var matches = css.match(/:\s?\d+vw/g);
      if(matches && matches.length) for (var i in matches){
        var match = matches[i].match(/:\s?(\d+)vw/);
        var num = parseFloat(match[1]);
        var newWidth = previewWidth*num/100;
        css = css.replace(match[0],':'+newWidth+'px');
      }

      var copy = block.source;
       block.preview = copy.replace(/<style>([\s\S]*?)<\/style>/,'<style>'+css+'</style>');
    },


  },
  mounted(){
    this.$http.get('/modules.php')
    .then((response)=> {
      this.modules = response.data
    }).catch((error) =>  { console.error(error); if(error.response) alert(error.response.data); })
  }
}
</script>

